import '../App.css';
import React, { forwardRef, useEffect, useState } from 'react';
import Wrapper from './ContainerWrapper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import regionData from './data_files/rajonu_sarasas.json';
import { MultiSelect } from 'react-multi-select-component';
import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';

import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Export from '@material-ui/icons/GetApp';

import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';


const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <Export {...props} ref={ref} />)
};

export const StatsTreedata = ({rowData, selectedTime}) => {
    var treeSchoolsTableData = null;
    const [data, setData] = useState(null);
    const [err, setErr] = useState(null);


};

function SchoolStats({ device }) {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.data);
    const [loading, setLoading] = useState(false);
    const [buttonActive, setButtonActive] = useState(false);
    const [buttonPressed, setButtonStatus] = useState(false);
    const [selected, setSelected] = useState([]);
    const [notSelected, setNotSelected] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectedTime, setSelectedTime] = useState([]);
    const [prepDownload, setPrepDownload] = useState(false);

    async function downloadExcel() {
        setPrepDownload(true);
        var schoolsDataForExcel = [];
        var m = 0;

        state.schoolsStatisticsTableData.sort((a, b) => a.Mokykla.localeCompare(b.Mokykla));

        const newData = state.schoolsStatisticsTableData.map(row => {
            delete row.tableData
            return row
        });

        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'mokyklos');

        let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });

        XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

        var currentDate = moment().format('YYYY-MM-DD');
        var fileName = currentDate + ' mokyklu statistikos.xlsx';

        XLSX.writeFile(workBook, fileName);
    };

    const timeDropdown = [
        { value: '1d', label: '< 24' },
        { value: '7d', label: '< 7d.' },
        { value: '30d', label: '< 30d.' },
        { value: '12m', label: '< 12mėn.' }
    ];

    useEffect(() => {
       formData(); 
    }, []);

    async function formData() {
        dispatch({ type: 'SET_LOADING', payload: true });
        await getSchoolsDropdown();
        const dropdown = await getRegionsDropdown();
        dispatch({ type: 'SECOND_SCHOOLS_STATISTICS_DROPDOWN_REGIONS', payload: dropdown });
    };

    async function getSchoolsDropdown() {
        await axios
            .get('/api/formStatisticsLocationsDropdown')
            .then((res) => {
                dispatch({
                    type: 'FIRST_SCHOOLS_STATISTICS_DROPDOWN_SCHOOLS',
                    payload: res.data.result
                });
            })
            .catch((err) => {
                dispatch({ type: 'SET_LOADING', payload: false });
            });
    };

    async function getRegionsDropdown() {
        var dropdown = [];
        for(var i = 0; i < regionData.length; i++) {
            var v = [
                {
                    eil_nr: regionData[i].eil_nr,
                    pavadinimas: regionData[i].pavadinimas,
                    raktas: regionData[i].key
                }
            ];
            dropdown[i] = {
                value: v,
                label: regionData[i].pavadinimas
            };
        }

        return dropdown;
    };

    const valueRendererRegions = (selected) => {
        if (selected.length > 1) {
            return selected.length + ' pasirinkti rajonai...';
        }
    };

    const valueRendererSchools = (selected) => {
        if (selected.length > 1) {
            return selected.length + ' pasirinktos mokyklos...';
        }
    };

    async function handleRegionChange(selRegion) {
        dispatch({ type: 'SET_LOADING', payload: true });
        dispatch({ type: 'FIRST_SCHOOLS_STATISTICS_DROPDOWN_SCHOOLS', payload: null });
        if (selRegion.length > 0) {
            await axios
                .post('/api/getLocationsByRegions', {regions: selRegion})
                .then((res) => {
                    dispatch({ type: 'FIRST_SCHOOLS_STATISTICS_DROPDOWN_SCHOOLS', payload: res.data.result });
                })
                .catch((err) => {
                    console.error(err);
                    dispatch({ type: 'SET_LOADING', payload: false });
                });
        } else {
            await getSchoolsDropdown();
        }

        setSelectedRegion(selRegion);
        dispatch({ type: 'SET_LOADING', payload: false });
    };

    const handleSchoolChange = (selSchool) => {
        if (selSchool === null) {
            setSelected(null);
        } else {
            setSelected(selSchool);
        }
    };

    const handleTimeChange = (selTime) => {
        if (selTime.length != 1) {
            var t = [selTime[1]];
            setSelectedTime(t);
        } else if (selTime.length == 1) {
            setSelectedTime(selTime);
        }
    };

    async function handleButtonClick() {
        setButtonStatus(true);
        setLoading(true);
        setButtonActive(true);

        dispatch({ type: 'SCHOOLS_STATISTICS_TABLE_DATA', payload: null });

        if (selectedTime.length === 0 || selected.length === 0) {
            setNotSelected(true);
            setButtonStatus(false);
            setButtonActive(false);
        } else {
            setNotSelected(false);
            await axios
                .post('/api/calculateStatisticsTableData', {schools: selected, period: selectedTime})
                .then((res) => {
                    setButtonStatus(false);
                    dispatch({type: 'SCHOOLS_STATISTICS_TABLE_DATA', payload: res.data.result});
                    dispatch({ type: 'SET_LOADING', payload: 'tableData' });
                    setButtonActive(false);
                })
                .catch((err) => {
                    console.log(err);
                });

            setLoading(false);
        }
    };

    return (
        <div className='anim-fade-in'>
            <div>
                <div
                    style={{
                        width: '99%',
                        minHeight: '90vh',
                        padding: 10,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '100px',
                        transition: 'all 0.1s ease-in-out'
                    }}
                >
                    <Wrapper
                        title='Statistika'
                        sub='Statistika'
                        styles={{
                            marginBottom: 15,
                        }}
                    >
                        {state.firstSchoolsStatsDropdownSchools === null && (
                            <p
                                style={{
                                    height: '3rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                Kraunama...
                                <CircularProgress size={25} style={{ marginLeft: 10 }} />
                            </p>
                        )}
                        { state.firstSchoolsStatsDropdownSchools !== null && state.secondSchoolsStatsDropdownRegions !== null && (
                            <>
                                <div
                                    style={{
                                        marginTop: 45,
                                        marginBottom: 90
                                    }}
                                >
                                    <table
                                        border='0'
                                        width='100%'
                                    >
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '20%' }} />
                                        <col style={{ width: '20%' }} />
                                        <thead>
                                            <tr>
                                                <th>Pasirinkite rajoną (-us):</th>
                                                <th>Pasirinkite mokyklą (-as):</th>
                                                <th>Laikotarpis:</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{
                                                    paddingLeft: 30,
                                                }}>
                                                    <MultiSelect
                                                        flexDirection='false'
                                                        options={state.secondSchoolsStatsDropdownRegions}
                                                        value={selectedRegion}
                                                        labelledBy='Selected'
                                                        valueRenderer={valueRendererRegions}
                                                        onChange={(selectedRegion) => {
                                                            handleRegionChange(selectedRegion);
                                                        }}
                                                        overrideStrings={{
                                                            'allItemsAreSelected': 'Pasirinkti visi rajonai',
                                                            'clearSearch': 'Išvalyti paiešką',
                                                            'clearSelected': 'Išvalyti pasirinkimus',
                                                            'noOptions': 'Nėra pasirinkimų',
                                                            'search': 'Paieška',
                                                            'selectAll': 'Pasirinkti viską',
                                                            'selectAllFiltered': 'Pasirinkti viską (išfiltruoti)',
                                                            'selectSomeItems': 'Pasirinkti...',
                                                            'create': 'Sukurti'
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <MultiSelect
                                                        flexDirection='false'
                                                        options={state.firstSchoolsStatsDropdownSchools}
                                                        value={selected}
                                                        onChange={(selected) => {
                                                            handleSchoolChange(selected);
                                                        }}
                                                        labelledBy='Select'
                                                        valueRenderer={valueRendererSchools}
                                                        overrideStrings={{
                                                            'allItemsAreSelected': 'Pasirinktos visos mokyklos',
                                                            'clearSearch': 'Išvalyti paiešką',
                                                            'clearSelected': 'Išvalyti pasirinkimus',
                                                            'noOptions': 'Nėra pasirinkimų',
                                                            'search': 'Paieška',
                                                            'selectAll': 'Pasirinkti viską',
                                                            'selectAllFiltered': 'Pasirinkti viską (išfiltruoti)',
                                                            'selectSomeItems': 'Pasirinkti',
                                                            'create': 'Sukurti'
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <MultiSelect
                                                        hasSelectAll={false}
                                                        closeOnChangeValue={true}
                                                        disableSearch={true}
                                                        valueRenderer={false}
                                                        flexDirection='false'
                                                        options={timeDropdown}
                                                        value={selectedTime}
                                                        onChange={(selectedTime) => {
                                                            if (selectedTime.length !== 0) {
                                                                handleTimeChange(selectedTime);
                                                            }
                                                        }}
                                                        labelledBy='Select'
                                                        overrideStrings={{
                                                            'allItemsAreSelected': 'Pasirinkti visi laiškai',
                                                            'clearSearch': 'Išvalyti paiešką',
                                                            'clearSelected': 'Išvalyti pasirinkimus',
                                                            'noOptions': 'Nėra pasirinkimų',
                                                            'search': 'Paieška',
                                                            'selectAll': 'Pasirinkti viską',
                                                            'selectAllFiltered': 'Pasirinkti viską (išfiltruoti)',
                                                            'selectSomeItems': 'Pasirinkti...',
                                                            'create': 'Sukurti'
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Button
                                                        style={{
                                                            height: 35,
                                                            minWidth: 120,
                                                            outline: 'none'
                                                        }}
                                                        variant='contained'
                                                        disabled={buttonActive}
                                                        onClick={() => {
                                                            handleButtonClick()
                                                        }}
                                                    >
                                                        Gauti
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                        {loading && buttonPressed && (
                            <p
                                style={{
                                    height: '3rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                Kraunama...
                                <CircularProgress size={25} style={{ marginLeft: 10 }} />
                            </p>
                        )}
                        {state.loading === 'tableData' && state.schoolsStatisticsTableData && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <MaterialTable
                                    style={{
                                        width: '90%',
                                        padding: 50,
                                        marginLeft: '5%',
                                        marginRight: '5%',
                                        textAlign: 'center',
                                        boxShadow:
                                        'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px, -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                                    }}
                                    localization={{
                                        header: {
                                            actions: ''
                                        },
                                        toolbar: {
                                            searchPlaceholder: 'Paieška...',
                                            searchTooltip: 'Paieška'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Įrašų nerasta.'
                                        },
                                        pagination: {
                                            labelRowsSelect: 'Eil.',
                                            firstTooltip: 'Pirmas',
                                            previuosTooltip: 'Ankstesnis',
                                            nextTooltip: 'Sekantis',
                                            lastTooltip: 'Paskutinis'
                                        }
                                    }}

                                    icons={tableIcons}
                                    columns={[
                                        {
                                            title: 'Mokykla',
                                            field: 'Mokykla'
                                        },
                                        {
                                            title: 'Įdiegtas AP kiekis',
                                            field: 'Įdiegtas AP kiekis'
                                        },
                                        {
                                            title: 'Vid. parsiuntimo srautas (RX) (Mbps)',
                                            field: 'Vid. parsiuntimo srautas (RX) (Mbps)'
                                        },
                                        {
                                            title: 'Maks. parsiuntimo srautas (RX) (Mbps)',
                                            field: 'Maks. parsiuntimo srautas (RX) (Mbps)'
                                        },
                                        {
                                            title: 'Vid. išsiuntimo srautas (RX) (Mbps)',
                                            field: 'Vid. išsiuntimo srautas (RX) (Mbps)'
                                        },
                                        {
                                            title: 'Maks. išsiuntimo srautas (RX) (MBps)',
                                            field: 'Maks. išsiuntimo srautas (RX) (Mbps)'
                                        },
                                        {
                                            title: 'Vid. prisijungusių įrenginių sk.',
                                            field: 'Vid. prisijungusių įrenginių sk.'
                                        },
                                        {
                                            title: 'Maks. prisijungusių įrenginių sk.',
                                            field: 'Maks. prisijungusių įrenginių sk.'
                                        }
                                    ]}
                                    data={state.schoolsStatisticsTableData}
                                    options={{
                                        sorting: false,
                                        draggable: false,
                                        pageSizeOptions: [8, 15, 20, 100],
                                        pageSize: 8,
                                        columnButtons: false,
                                        exportButton: false,
                                        filtering: false,
                                        showTitle: false,
                                        headerStyle: {
                                            color: 'black',
                                            fontWeight: 'bold'
                                        }
                                    }}
                                    actions={[
                                        {
                                            icon: () => <Export />,
                                            tooltip: 'Eksportuoti į Excel',
                                            onClick: () => { downloadExcel(); },
                                            isFreeAction: true
                                        }
                                    ]}
                                    // detailPanel={[
                                    //     rowData => ({
                                    //         disabled: rowData.expandable,
                                    //         icon: () => {
                                    //             if (!rowData.expandable) {
                                    //                 return <ChevronRight />
                                    //             } else {
                                    //                 return false;
                                    //             }
                                    //         },
                                    //         render: () => {
                                    //             if (
                                    //                 rowData['Vid. parsiuntimo srautas (RX) (Mbps)'] === '-'
                                    //                 &&
                                    //                 rowData['Maks. parsiuntimo srautas (RX) (Mbps)'] === '-'
                                    //                 &&
                                    //                 rowData['Vid. išsiuntimo srautas (RX) (Mbps)'] === '-'
                                    //                 &&
                                    //                 rowData['Maks. išsiuntimo srautas (RX) (Mbps)'] === '-'
                                    //                 &&
                                    //                 rowData['Vid. prisijungusių įrenginių sk.'] === '-'
                                    //                 &&
                                    //                 rowData['Maks. prisijungusių įrenginių sk.'] === '-'
                                    //             ) {
                                    //                 return (
                                    //                     <div
                                    //                         style={{
                                    //                             backgroundColor: '#f8f8f8',
                                    //                             padding: 20
                                    //                         }}
                                    //                     >
                                    //                         <StatsTreeData
                                    //                             rowData={rowData}
                                    //                             selectedTime={selectedTime}
                                    //                         />
                                    //                     </div>
                                    //                 )
                                    //             } else {
                                    //                 return false;
                                    //             }
                                    //         }
                                    //     })
                                    // ]}
                                />
                            </div>
                        )}
                    </Wrapper>
                </div>
            </div>
        </div>
    )
};

export default SchoolStats;
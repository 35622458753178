import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import UnifUrls from "./device_update/UnifiUrlList";
import Alert from "../components/AlertMsg";
import socketIOClient from "socket.io-client";
import ErrIcon from "@material-ui/icons/ErrorOutlineRounded";
import CheckIcon from "@material-ui/icons/CheckCircleRounded";
import ConfirmModal from "../components/ConfirmModal";

function UpdateNetboxDevices({ close }) {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);
  const [output, setOutput] = useState([]);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(null);
  const [urls, setUrls] = useState(null);
  const [updating, setUpdating] = useState(false);
  const state = useSelector((state) => state.data);

  const messageRef = useRef();

  let socket;

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.75)",
    },
  }))(Tooltip);
  const handleClose = () => {
    close(false);
  };

  const deleteUrls = () => {
    setErr(null);
    setLoading(true);
    const datas = urls.filter((x) => x.checked);
    axios
      .delete("/api/unifiurls", { data: datas })
      .then((res) => {
        const checks = res.data.map((item) => {
          return { ...item, checked: false };
        });
        setUrls(checks);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setErr("Sistemos klaida.");
        setLoading(false);
      });
  };
  const Update = () => {
    setErr(null);
    setLoading(true);
    const datas = urls.filter((x) => x.checked);

    axios
      .post(
        "/api/updatewifilist",
        { data: datas },
        {
          timeout: 600000,
        }
      )
      .then((res) => {
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setErr("Sistemos klaida.");
        setLoading(false);
      });
  };

  const isChecked = useMemo(() => {
    if (urls) {
      const find = urls.find((x) => x.checked);
      if (find) {
        return true;
      }
    }
  }, [urls]);
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const setUpSockets = async () => {
    const tkn = getCookie("tkn");

    socket = socketIOClient("/", {
      rejectUnauthorized: false,
      query: {
        token: "Bearer " + tkn,
      },
    });
    socket.on("connect", function () {
      console.log("connected");
    });
    socket.on("upd", function (msg) {
      setMsg(msg);
    });
    socket.on("updState", function (msg) {
      setUpdating(msg.text);
    });
  };
  useEffect(() => {
    setUpSockets();

    return () => {
      socket && socket.close();
    };
  }, []);
  useEffect(() => {
    if (msg) {
      const newOutput = [...output, msg];
      // console.log(newOutput);
      setOutput(newOutput);
      if (messageRef.current) {
        messageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  }, [msg]);
  return (
    <>
      <DialogContent>
        {err && <Alert msgType={"error"} txt={err} />}
        {modal && (
          <ConfirmModal
            closeModal={() => setModal(false)}
            reboot={deleteUrls}
            title={
              <div style={{ marginRight: 20 }}>
                Ar tikrai norite ištrinti adresatą(-us)?
              </div>
            }
            txt={"Pažymėti įrašai bus ištrinti."}
            btTxt={"Ištrinti"}
          />
        )}
        <DialogContentText
          id="alert-dialog-description"
          style={{ margin: "1%", marginTop: 10, width: 1000 }}
        >
          <small
            style={{
              opacity: 0.6,
              margin: 0,
              padding: 0,
              color: "#6495ed",
            }}
          >
            Unifi kontrolerių sąrašas
          </small>
          <div
            style={{
              borderRadius: 8,
              border: "1px solid #d3d3d3",
              padding: "1%",
            }}
          >
            <UnifUrls
              urls={urls}
              setUrls={setUrls}
              setErr={setErr}
              setModal={setModal}
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <small
              style={{
                opacity: 0.6,
                margin: 0,
                padding: 0,
                color: "#6495ed",
              }}
            >
              Output
            </small>
            <div
              style={{
                borderRadius: 8,
                border: "1px solid #d3d3d3",
                padding: "1%",
                backgroundColor: "#333333",
                boxShadow: "inset 0px 0px 5px 0px rgba(0,0,0,0.75)",
                height: 500,
                overflowY: "scroll",
              }}
            >
              {output &&
                output.length > 0 &&
                output.map((row, index) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {row.type === "err" && (
                      <ErrIcon style={{ color: "#ff4500" }} />
                    )}
                    {row.type === "ok" && (
                      <CheckIcon style={{ color: "#32cd32" }} />
                    )}
                    <p key={index} style={{ color: "white", margin: 4 }}>
                      {row.text}
                    </p>
                  </div>
                ))}
              <div ref={messageRef} style={{ marginTop: 50 }} />
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div style={{ width: "100%" }} className="center-flex">
          <Button
            onClick={() => Update()}
            variant="outlined"
            disabled={!isChecked || updating}
          >
            Atnaujinti
          </Button>
        </div>
      </DialogActions>
    </>
  );
}

export default UpdateNetboxDevices;

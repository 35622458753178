import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import UnifiUrlSelect from "./device_update/UnifiUrlSelect";
import AlertMsg from "./AlertMsg";
import { Typography } from "@material-ui/core";

export default function CreateDevice({ close, urls, setUrls }){
    const dispatch = useDispatch();
    const state = useSelector((state) => state.data);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);
    const [resData, setResData] = useState(null);
    const [checked, setChecked] = useState(false);
    const [connectionExistsChecked, setConnectionExistsChecked] = useState(false);
    const [litnetDeviceChecked, setLitnetDeviceChecked] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState([]);
    const [selectedWarehouseDevice, setSelectedWarehouseDevice] = useState([]);
    const [selectedUnifiUrl, setSelectedUnifiUrl] = useState([]);

    const [formData, setFormData] = useState({
        connection: false,
        existing: false,
        litnetDevice: false,
        organisation: "",
        slug: "",
        unifi: "",
        mtIp: "",
        selectedDevice: "",
    });

    // var dropdown = [];

    useEffect(() => {
        formOrgData();
        formWarehouseDeviceData();
        formUnifiUrlData();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setResData(null);
        // Bevielis ir turi rysi
        if(checked === false && connectionExistsChecked === false){
            if(formData['unifi'] === ""){
                setErr("Prašome pasirinkti Unifi.");
            }
            if(formData['organisation'] === ""){
                setErr("Prašome pasirinkti organizaciją.");
            }

            if(formData['organisation'] !== "" && formData['unifi'] !== ""){
                setErr(null);
                axios
                    .put("/api/wirelessConnectionExists", {
                        formData,
                    })
                    .then((res) => {
                        setLoading(false);
                        setResData(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        setErr(err.response.data.error);
                    });
            }
        }
        // Bevielis ir naujas jungimas, Litnet irenginys
        if(checked === false && connectionExistsChecked === true && litnetDeviceChecked === false){ 
            if(formData['mtIp'] === ""){
                setErr("Prašome įrašyti IP adresą.");
            }
            if(formData['selectedDevice'] === ""){
                setErr("Prašome pasirinkti įrenginį.");
            }
            if(formData['unifi'] === ""){
                setErr("Prašome pasirinkti Unifi.");
            }
            if(formData['organisation'] === ""){
                setErr("Prašome pasirinkti organizaciją.");
            }

            if(formData['organisation'] !== "" && formData['unifi'] !== "" && formData['selectedDevice'] !== "" && formData['mtIp'] !== ""){
                setErr(null);
                axios
                    .put("/api/wirelessNewConnectionLitnetDevice", {
                        formData,
                    })
                    .then((res) => {
                        setLoading(false);
                        setResData(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        setErr(err.response.data.error);
                    });
            }
        }
        //Bevielis ir naujas jungimas, ne Litnet irenginys
        if(checked === false && connectionExistsChecked === true && litnetDeviceChecked === true) {
            if(formData['mtIp'] === ""){
                setErr("Prašome įrašyti IP.")
            }
            if(formData['unifi'] === ""){
                setErr("Prašome pasirinkti Unifi.");
            }
            if(formData['organisation'] === ""){
                setErr("Prašome pasirinkti organizaciją.");
            }

            if(formData['organisation'] !== "" && formData['unifi'] !== "" && formData['mtIp'] !== ""){
                setErr(null);
                axios
                    .put("/api/wirelessNewConnectionNotLitnetDevice", {
                        formData,
                    })
                    .then((res) => {
                        setLoading(false);
                        setResData(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        setErr(err.response.data.error);
                    });
            }
        }
        //L2 ir Litnet irenginys
        if(checked === true && litnetDeviceChecked === false){
            if(formData['selectedDevice'] === ""){
                setErr("Prašome pasirinkti įrenginį.");
            }
            if(formData['mtIp'] === ""){
                setErr("Prašome įrašyti IP.");
            }
            if(formData['organisation'] === ""){
                setErr("Prašome pasirinkti organizaciją.");
            }

            if(formData['selectedDevice'] !== "" && formData['mtIp'] !== "" && formData['organisation'] !== ""){
                setErr(null);
                axios
                    .put("/api/wiredLitnetDevice", {
                        formData,
                    })
                    .then((res) => {
                        setLoading(false);
                        setResData(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        setErr(err.response.data.error);
                    });
            }
        }
        //L2 ir ne Litnet irenginys
        if(checked === true && litnetDeviceChecked === true) {
            if(formData['mtIp'] === ""){
                setErr("Prašome įrašyti IP.");
            }
            if(formData['organisation'] === ""){
                setErr("Prašome pasirinkti organizaciją.");
            }

            if(formData['organisation'] !== "" && formData['mtIp'] !== ""){
                setErr(null);
                axios
                    .put("/api/wiredNotLitnetDevice", {
                        formData,
                    })
                    .then((res) => {
                        setLoading(false);
                        setResData(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        setErr(err.response.data.error);
                    });
            }
        }

    };

    const switchHandler = (event) => {
        setChecked(event.target.checked);

        const newData = { ...formData };
        newData["connection"] = event.target.checked;
        // if(event.target.checked) {
        newData["existing"] = false;
        newData["unifi"] = ""; //null;
        // }
        newData["mtIp"] = "";
        setSelectedUnifiUrl([]);
        setConnectionExistsChecked(false);
        setLitnetDeviceChecked(false);
        setFormData(newData);
    };

    const connectionExistsSwitchHandler = (event) => {
        setConnectionExistsChecked(event.target.checked);

        const newData = { ...formData };
        newData["existing"] = event.target.checked;
        if(!event.target.checked) {
            newData["selectedDevice"] = ""; //null;
            setSelectedWarehouseDevice([]);
            newData["mtIp"] = ""; //null;
        }
        setFormData(newData);
    };

    const litnetDeviceSwitchHandler = (event) => {
        setLitnetDeviceChecked(event.target.checked);

        const newData = { ...formData };
        newData["litnetDevice"] = event.target.checked;
        if(event.target.checked){
            newData["selectedDevice"] = ""; //null;
            setSelectedWarehouseDevice([]);
        }
        setFormData(newData);
    };

    async function formOrgData(){
        // const data = await searchOrgData();
        // const locations = await searchLocationData();
        // formOrgDropdown(data, locations);
        const data = await searchLocationData();
        formOrgDropdown(data);
    };

    async function searchLocationData() {
        var data = null;
        dispatch({ type: "SET_SEARCH_DATA", payload: [] });
        await axios
            .get("/api/netboxlocations")
            .then((res) => {
                dispatch({
                    type: "SET_ALL_LOCATIONS",
                    payload: res.data.result.results,
                });
                data = res.data.result.results;
            })
            .catch((err) => {
                dispatch({ type: "SET_LOADING", payload: false });
            })
        return data;
    };

    // async function searchOrgData(){
    //     var data = null;
    //     dispatch({type: "SET_SEARCH_DATA", payload: [] });
    //     await axios
    //         .get("/api/netboxsites")
    //         .then((res) => {
    //             dispatch({
    //                 type: "SET_SEARCH_DATA",
    //                 payload: res.data.result.results,
    //             });
    //             data = res.data.result.results;
    //         })
    //         .catch((err) => {
    //             dispatch({ type: "SET_LOADING", payload: false });
    //         });
    //     return data;
    // };

    // async function searchLocationData() {
    //     var data = null;
    //     await axios
    //         .get("/api/netboxlocations")
    //         .then((res) => {
    //             data = res.data.result.results;
    //         })
    //         .catch((err) => {
    //             dispatch({ type: "SET_LOADING", payload: false });
    //         })
    //     return data;
    // };

    const formOrgDropdown = (data) => {
        var dropdown = [];
        for( var i = 0; i < data.length; i++) {
            var val = [
                {
                    name: data[i].name,
                    id: data[i].id,
                    site_id: data[i].site.id,
                    slug: data[i].slug,
                }
            ]
            dropdown[i] = {
                value: val,
                label: data[i].name
            }
        }
        dispatch({ type: "ORGANISATIONS_DROPDOWN", payload: dropdown });
    };

    // const formOrgDropdown = (data, locations) => {
    //     var dropdown = [];
    //     for(var i = 0; i < data.length; i++){
    //         var val = [
    //             {
    //                 name: data[i].name,
    //                 id: data[i].id,
    //                 slug: data[i].slug,
    //                 location: false,
    //             }
    //         ]
    //         dropdown[i] = {
    //             value: val,
    //             label: data[i].name
    //         }
    //     }
    //     var j = dropdown.length;
    //     for (var i = 0; i < locations.length; i++){
    //         var val = [
    //             {
    //                 name: locations[i].name,
    //                 id: locations[i].id,
    //                 site_id: locations[i].site.id,
    //                 location_id: locations[i].id,
    //                 slug: locations[i].slug,
    //                 location: true,
    //             }
    //         ]
    //         dropdown[j] = {
    //             value: val,
    //             label: locations[i].name,
    //         }
    //         j++;
    //     }
    //     dispatch({ type: "ORGANISATIONS_DROPDOWN", payload: dropdown });
    // };

    const handleOrgChange = (selOrg) => {
        if(selOrg.length !== 1) {
            var r = [selOrg[1]];
            setSelectedOrg(r);
        } else if (selOrg.length === 1){
            setSelectedOrg(selOrg);
        }

        const newData = { ...formData };
        newData["organisation"] = selOrg[selOrg.length - 1];
        setFormData(newData);
    };

    async function formWarehouseDeviceData(){
        const data = await warehouseDeviceData();
        formWarehouseDropdown(data);
    };

    async function warehouseDeviceData(){
        var data = null;
        await axios
            .get("/api/getWarehouseDevices")
            .then((res) => {
                data = res.data.result.results;
            })
            .catch((err) => {
                dispatch({ type: "SET_LOADING", payload: false });
            })
        return data;
    };

    const formWarehouseDropdown = (data) => {
        var dropdown = [];
        for(var i = 0; i < data.length; i++){
            var val = [
                {
                    id: data[i].id,
                    serial: data[i].serial,
                    asset_tag: data[i].asset_tag,
                }
            ]
            dropdown[i] = {
                value: val,
                label: data[i].serial
            }
        }
        dispatch({ type: "WAREHOUSE_DEVICES_DROPDOWN", payload: dropdown });
    };

    const handleWarehouseDeviceChange = (selWarehouseDevice) => {
        if(selWarehouseDevice.length !== 1) {
            var r = [selWarehouseDevice[1]];
            setSelectedWarehouseDevice(r);
        } else if (selWarehouseDevice.length === 1){
            setSelectedWarehouseDevice(selWarehouseDevice);
        }

        const newData = { ...formData };
        newData["selectedDevice"] = selWarehouseDevice[selWarehouseDevice.length - 1];
        setFormData(newData);
    };

    async function formUnifiUrlData(){
        const data = await unifiUrlData();
        formUnifiUrlDropdown(data);
    };

    async function unifiUrlData(){
        var data = null;
        await axios
            .get("/api/unifiurls")
            .then((res) => {
                data = res.data;
            })
            .catch((err) => {
                dispatch({ type: "SET_LOADING", payload: false });
            })
        return data;
    };

    const formUnifiUrlDropdown = (data) => {
        var dropdown = [];
        for(var i = 0; i < data.length; i++){
            var val = [
                {
                    id: data[i].id,
                    name: data[i].name,
                    password: data[i].password,
                    url: data[i].url,
                }
            ]
            dropdown[i] = {
                value: val,
                label: data[i].url,
            }
        }
        dispatch({ type: "UNIFI_URL_DROPDOWN", payload: dropdown });
    };

    const handleUnifiUrlChange = (selUnifiUrl) => {
        if(selUnifiUrl.length !== 1) {
            var r = [selUnifiUrl[1]];
            setSelectedUnifiUrl(r);
        } else if (selUnifiUrl.length === 1){
            setSelectedUnifiUrl(selUnifiUrl);
        }

        const newData = { ...formData };
        newData["unifi"] = selUnifiUrl[selUnifiUrl.length -1];
        setFormData(newData);
    };

    const handleTextIP = (e) => {
        const newData = { ...formData};
        newData["mtIp"] = e.target.value;
        setFormData(newData);
    };

    const handleTextSlug = (e) => {
        const newData = { ...formData};
        newData["slug"] = e.target.value;
        setFormData(newData);
    }

    const handleClose = () => {
        close(false);
    };

    return (
        <DialogContent>
            {loading && (
                <div className="centered">
                    Kraunama...
                    <CircularProgress size={25} style={{ marginLeft: 10 }} />
                </div>
            )}
            <form
                autoComplete="off"
                onSubmit={(e) => handleSubmit(e)}
                style={{
                    pointerEvents: loading && "none",
                    opacity: loading && 0.4,
                }}
            >
                {err && (
                    <div
                        style={{
                            padding: 7,
                        }}
                    >
                        <AlertMsg msgType={"error"} txt={err} />
                    </div>
                )}
                {resData && (
                    <div
                        style={{
                            padding: 7,
                        }}
                    >
                        <AlertMsg msgType={"success"} txt={"Mikrotikas priskirtas sėkmingai!"} />
                    </div>
                )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: 1000,
                        height: 500,
                    }}
                >
                    <div
                        style={{
                            margin: "1%",
                            marginTop: 25,
                            width: "100%",
                            paddingBottom: "3.5%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Typography>Belaidis jungimas</Typography>
                            <Switch
                                checked={checked}
                                color="primary"
                                size="medium"
                                onChange={switchHandler}
                            />
                            <Typography>Laidinis jungimas</Typography>
                        </div>
                        <Divider />
                        <div
                            style={{
                                marginTop: 25
                            }}
                        >
                            {state.orgDropdown == null && (
                                <p
                                    style={{
                                        height: "3rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItem: "center",
                                    }}
                                >
                                    Kraunama...
                                    <CircularProgress size={25} style={{ marginLeft: 10 }} />
                                </p>
                            )}
                            {state.orgDropdown != null && (
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItem: "center",
                                            marginBottom: 5,
                                        }}
                                    >
                                        <MultiSelect
                                            name="Organizacija"
                                            hasSelectAll={false}
                                            closeOnChangedValue={true}
                                            disableSearch={false}
                                            valueRenderer={false}
                                            labeledBy="Select"
                                            flexDirection="false"
                                            options={state.orgDropdown}
                                            value={selectedOrg}
                                            onChange={(selectedOrg) => {
                                                if(selectedOrg.length !== 0) {
                                                    handleOrgChange(selectedOrg);
                                                }
                                            }}
                                            overrideStrings={{
                                                "allItemsAreSelected": "Pasirinkta viskas",
                                                "clearSearch": "Išvalyti paiešką",
                                                "clearSelected": "Išvalyti pasirinkimus",
                                                "noOptions": "Nėra pasirinkimų",
                                                "search": "Paieška",
                                                "selectAll": "Pasirinkti viską",
                                                "selectedAllFiltered": "Pasirinkti viską (išfiltruoti)",
                                                "selectSomeItems": "Pasirinkite organizaciją...",
                                                "create": "Sukurti"
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignitems: "center"
                                        }}
                                    >
                                        {/* <p
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                marginRight: 10,
                                                marginTop: 20,
                                            }}
                                        >
                                            Org. trumpinys: {" "}
                                        </p> */}
                                        {/* <TextField
                                            name="slug"
                                            label="Org. trumpinys"
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                marginRight: 20,
                                                marginLeft: 20,
                                                marginBottom: 25,
                                                width: 200,
                                            }}
                                            size="small"
                                            onChange={(e) => handleTextSlug(e)}
                                            value={FormData.slug}
                                        /> */}
                                    </div>
                                </div>
                            )}
                            {!checked && (
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignitems: "center"
                                        }}
                                    >
                                        <TextField
                                            name="slug"
                                            label="Org. trumpinys"
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                marginRight: 20,
                                                marginLeft: 20,
                                                marginBottom: 25,
                                                width: 200,
                                            }}
                                            size="small"
                                            onChange={(e) => handleTextSlug(e)}
                                            value={FormData.slug}
                                        />
                                    </div>
                                    <div>
                                        {state.unifiUrlDropdown == null && (
                                            <p
                                                style={{
                                                    height: "3rem",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItem: "center",
                                                }}
                                            >
                                                Kraunama...
                                                <CircularProgress size={25} style={{ marginLeft: 10 }} />
                                            </p>
                                        )}
                                        {state.unifiUrlDropdown != null && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItem: "center",
                                                    marginBottom: 25,
                                                }}
                                            >
                                                <MultiSelect
                                                    name="Unifi Url"
                                                    hasSelectAll={false}
                                                    closeOnChangedValue={true}
                                                    disableSearch={false}
                                                    valueRenderer={false}
                                                    labeledBy="Select"
                                                    flexDirection="false"
                                                    options={state.unifiUrlDropdown}
                                                    value={selectedUnifiUrl}
                                                    onChange={(selectedUnifiUrl) => {
                                                        if(selectedUnifiUrl !== 0) {
                                                            handleUnifiUrlChange(selectedUnifiUrl);
                                                        }
                                                    }}
                                                    overrideStrings={{
                                                        "allItemsAreSelected": "Pasirinkta viskas",
                                                        "clearSearch": "Išvalyti paiešką",
                                                        "clearSelected": "Išvalyti pasirinkimus",
                                                        "noOptions": "Nėra pasirinkimų",
                                                        "search": "Paieška",
                                                        "selectAll": "Pasirinkti viską",
                                                        "selectedAllFiltered": "Pasirinkti viską (išfiltruoti)",
                                                        "selectSomeItems": "Pasirinkite Unifi...",
                                                        "create": "Sukurti"
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <p
                                            style={{
                                                height: "3rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItem: "center",
                                                marginBottom: -20
                                            }}
                                        >
                                            Ar turi Litnet laidinį ryšį?
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        {/* <Typography>Turi ryšį</Typography> */}
                                        <Typography>Taip</Typography>
                                        <Switch
                                            checked={connectionExistsChecked}
                                            color="primary"
                                            size="medium"
                                            onChange={(connectionExistsSwitchHandler)}
                                        />
                                        {/* <Typography>Naujas jungimas</Typography> */}
                                        <Typography>Ne</Typography>
                                    </div>
                                    <div>
                                        {connectionExistsChecked && (
                                            <div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            height: "3rem",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItem: "center",
                                                            marginBottom: -20
                                                        }}
                                                    >
                                                        Ar Mikrotikas yra Litnet?
                                                    </p>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {/* <Typography>Litnet įrenginys</Typography> */}
                                                    <Typography>Taip</Typography>
                                                    <Switch
                                                        checked={litnetDeviceChecked}
                                                        color="primary"
                                                        size="medium"
                                                        onChange={(litnetDeviceSwitchHandler)}
                                                    />
                                                    {/* <Typography>Ne Litnet įrenginys</Typography> */}
                                                    <Typography>Ne</Typography>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            padding: 0,
                                                            margin: 0,
                                                            marginRight: 10,
                                                            marginTop: 20,
                                                        }}
                                                    >
                                                        Mikrotik IP adresas:{" "}
                                                    </p>
                                                    <TextField
                                                        name="mtIp"
                                                        label="Mikrotik IP"
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            marginRight: 25,
                                                            width: 200,
                                                        }}
                                                        size="small"
                                                        onChange={(e) => handleTextIP(e)}
                                                        value={FormData.mtIp}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {!litnetDeviceChecked && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {state.warehouseDevicesDropdown == null && (
                                                                <p
                                                                    style={{
                                                                        height: "3rem",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    Kraunama...
                                                                    <CircularProgress size={25} style={{ marginLeft: 10 }} />
                                                                </p>
                                                            )}
                                                            {state.warehouseDevicesDropdown != null && (
                                                                <div
                                                                    style={{
                                                                        marginTop: 25,
                                                                    }}
                                                                >
                                                                    <MultiSelect
                                                                        name="Įrenginys"
                                                                        hasSelectAll={false}
                                                                        closeOnChangedValue={true}
                                                                        disableSearch={false}
                                                                        valueRenderer={false}
                                                                        labeledBy="Select"
                                                                        flexDirection="false"
                                                                        options={state.warehouseDevicesDropdown}
                                                                        value={selectedWarehouseDevice}
                                                                        onChange={(selectedWarehouseDevice) => {
                                                                            if(selectedWarehouseDevice.length !== 0) {
                                                                                handleWarehouseDeviceChange(selectedWarehouseDevice);
                                                                            }
                                                                        }}
                                                                        overrideStrings={{
                                                                            "allItemsAreSelected": "Pasirinkta viskas",
                                                                            "clearSearch": "Išvalyti paiešką",
                                                                            "clearSelected": "Išvalyti pasirinkimus",
                                                                            "noOptions": "Nėra pasirinkimų",
                                                                            "search": "Paieška",
                                                                            "selectAll": "Pasirinkti viską",
                                                                            "selectedAllFiltered": "Pasirinkti viską (išfiltruoti)",
                                                                            "selectSomeItems": "Pasirinkite įrenginį...",
                                                                            "create": "Sukurti"
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {checked && (
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems:"center"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                marginRight: 10,
                                                marginTop: 20,
                                            }}
                                        >
                                            Mikrotik IP adresas:{" "}
                                        </p>
                                        <TextField
                                            name="mtIp"
                                            label="Mikrotik IP"
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                marginRight: 25,
                                                width: 200,
                                            }}
                                            size="small"
                                            onChange={(e) => handleTextIP(e)}
                                            value={FormData.mtIp}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <p
                                            style={{
                                                height: "3rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItem: "center",
                                                marginTop: 25,
                                                marginBottom: -20
                                            }}
                                        >
                                            Ar Mikrotikas yra Litnet?
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* <Typography>Litnet įrenginys</Typography> */}
                                        <Typography>Taip</Typography>
                                        <Switch
                                            checked={litnetDeviceChecked}
                                            color="primary"
                                            size="medium"
                                            onChange={(litnetDeviceSwitchHandler)}
                                        />
                                        {/* <Typography>Ne Litnet įrenginys</Typography> */}
                                        <Typography>Ne</Typography>
                                    </div>
                                    {!litnetDeviceChecked && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {state.warehouseDevicesDropdown == null && (
                                                <p
                                                    style={{
                                                        height: "3rem",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    Kraunama...
                                                    <CircularProgress size={25} style={{ marginLeft: 10 }} />
                                                </p>
                                            )}
                                            {state.warehouseDevicesDropdown != null && (
                                                <div
                                                    style={{
                                                        marginTop: 25,
                                                    }}
                                                >
                                                    <MultiSelect
                                                        name="Įrenginys"
                                                        hasSelectAll={false}
                                                        closeOnChangedValue={true}
                                                        disableSearch={false}
                                                        valueRenderer={false}
                                                        labeledBy="Select"
                                                        flexDirection="false"
                                                        options={state.warehouseDevicesDropdown}
                                                        value={selectedWarehouseDevice}
                                                        onChange={(selectedWarehouseDevice) => {
                                                            if(selectedWarehouseDevice.length !== 0) {
                                                                handleWarehouseDeviceChange(selectedWarehouseDevice);
                                                            }
                                                        }}
                                                        overrideStrings={{
                                                            "allItemsAreSelected": "Pasirinkta viskas",
                                                            "clearSearch": "Išvalyti paiešką",
                                                            "clearSelected": "Išvalyti pasirinkimus",
                                                            "noOptions": "Nėra pasirinkimų",
                                                            "search": "Paieška",
                                                            "selectAll": "Pasirinkti viską",
                                                            "selectedAllFiltered": "Pasirinkti viską (išfiltruoti)",
                                                            "selectSomeItems": "Pasirinkite įrenginį...",
                                                            "create": "Sukurti"
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: 15,
                    }}
                >
                    <Button type="submit" color="primary">
                        Pridėti
                    </Button>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        Atšaukti
                    </Button>
                </div>
            </form>
        </DialogContent>
    )
}